import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import ButtonRow from '../layout/ButtonRow';
import PRow from '../layout/PRow';
import StandardButton from '../buttons/StandardButton';
import loginErrorImage from '../../assets/images/login-error-icon.svg'

const LoginErrorPage = () => {
    const loginUrl = `${process.env.REACT_APP_TSCOM_URL}/#login`;
    const helpUrl = `${process.env.REACT_APP_TSCOM_URL}/contact-us/`;
    return (
        <section className="container">
            <Row>
                <Col sm={{span: 12}} lg={{span: 11, offset: 1}} className="mb-5">
                    <div className="card text-font">
                        <div className="card-body mb-5">
                            <Container>
                                <PRow colSpan={ 10 }>
                                    <div className="d-block"><img src={loginErrorImage} className="login-error-image"/></div>
                                    <div className="h1-white mt-3 mb-3"><center>Oops!, something went wrong</center></div>
                                    <div className='p-center'>
                                        We were unable to futfill your login request. Although unlikely, this could be the result of system error or service outage. Please click below to restart the login process. If the problem persists please <a href={helpUrl} target="_blank" rel="noreferrer">contact us</a> for assistance.
                                    </div>
                                </PRow>
                                <ButtonRow>
                                    <StandardButton type="primary" href={loginUrl}>
                                        <strong>Login Again</strong>
                                    </StandardButton>
                                </ButtonRow>
                            </Container>
                        </div>
                    </div>
                </Col>
            </Row>
        </section>
    )
};

export default LoginErrorPage;
