import React from 'react';
import { Col, Row } from 'react-bootstrap'
import Logo from './Logo';

const Header = () => (
    <header className="container main-header mt-5 mb-4 mb-sm-5">
        <Row className="row" >
            <Col lg={{span: 11, offset: 1}}  className="logo-container d-flex justify-content-center">
                <Logo />
            </Col>
        </Row>
    </header>
);

export default Header;





