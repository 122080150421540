import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap'

const PRow = (props) => (
    <Row className="mt-3 mb-2">
        <Col md={{span: props.colSpan || 11, offset: 1}}>
            {props.children}            
        </Col>
    </Row>
);


PRow.propTypes = {
    children: PropTypes.node.isRequired,
    colSpan: PropTypes.number
}

export default PRow;





