import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import StandardButton from '../buttons/StandardButton';
import Title from '../layout/Title';

import OneInCircle from '../../assets/images/one-in-circle.svg';
import TwoInCircle from '../../assets/images/two-in-circle.svg';
import PRow from '../layout/PRow';
import BulletRow from '../layout/BulletRow';
import ButtonRow from '../layout/ButtonRow';
import useQuery from '../../hooks/usQuery';
import mfaWorkflowImage from '../../assets/images/mfa-workflow-image.svg'
import mfaWorkflowMobileImage from '../../assets/images/mfa-workflow-mobile-image.svg'

const SecurityNotice2FA = () => {
    let query = useQuery();
    let queryString = query.get("state") ? `/continue?state=${query.get("state")}` : '/continue';
    // eslint-disable-next-line no-undef
    let finalUrl = `${process.env.REACT_APP_SET_UP_URL}${queryString}`;
    // eslint-disable-next-line no-undef
    let helpUrl = `${process.env.REACT_APP_HELP_URL}`;
    return(
        <section className="container">
            <Row>
                <Col sm={{span: 12}} lg={{span: 11, offset: 1}} className="mb-5">
                    <div className="card text-font">
                        <div className="card-body">
                            <Container>
                                <Title showLock={true}>
                                    <h1>Secure Login Setup</h1>
                                </Title>
                                <BulletRow image={OneInCircle}>
                                    <p className="yellow-bullet">Set up two-factor authentication</p>
                                </BulletRow>
                                <PRow>
                                        <p>
                                            When you login from an untrusted device you will be asked to approve the login request using your mobile device. <a href={helpUrl} target="_blank" rel="noreferrer">Learn more</a>
                                        </p>
                                        <div className="d-none d-sm-none d-md-block d-lg-block, d-xl-block"><img src={mfaWorkflowImage} className="mfa-workflow-image"/></div>
                                        <div className="d-block d-sm-block d-md-none d-lg-none, d-xl-none"><img src={mfaWorkflowMobileImage} className="mfa-workflow-mobile-image"/></div>
                                </PRow>
                                <BulletRow image={TwoInCircle}>
                                    <p className="yellow-bullet">Choose your new username and password</p>
                                </BulletRow>
                                <PRow>  
                                    <p>
                                        You will be redirected to the Client Center to complete this task.
                                    </p>
                                </PRow>
                                <ButtonRow>
                                    <StandardButton type="primary" href={finalUrl}>
                                        <strong>Continue</strong>
                                    </StandardButton>
                                </ButtonRow>
                            </Container>
                        </div>
                    </div>
                </Col>
            </Row>
        </section>
    )
};

export default SecurityNotice2FA;
