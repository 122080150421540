import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap'
import LockIcon from '../icons/LockIcon';
import CompleteIcon from '../icons/CompleteIcon';
import HelpIcon from '../icons/HelpIcon';

const Title = (props) => (
    <Row className="mt-2">
        <Col xs={{span: 2}} sm={2} md={{span: 1}} className="text-left lock-icon">
            {props.showLock
                ? <LockIcon />
                : <CompleteIcon />
            }    
        </Col>
        <Col xs={10} sm={10} md={9} className="title my-auto">
            {props.children}
        </Col>
        <Col xs={{span: 0}} sm={0} md={{span: 2}} className="text-right d-none d-sm-none d-md-block">
            <HelpIcon />    
        </Col>
    </Row>
);


Title.propTypes = {
    children: PropTypes.node.isRequired,
    showLock: PropTypes.bool.isRequired
}

export default Title;





