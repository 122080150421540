import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap'

const BulletRow = (props) => (
    <Row className="mt-3 mb-3">
        <Col xs={2} md={{span: 1, offset: 1}} className="align-self-center">
            <img className="yellow-bullet-icon" src={props.image} alt={props.imageAlt} />
        </Col>
        <Col xs={10} md={{span: 10}} >
            {props.children}
        </Col>
    </Row>
);

BulletRow.propTypes = {
    children: PropTypes.node.isRequired,
    image: PropTypes.node.isRequired,
    imageAlt: PropTypes.string,
}

export default BulletRow;





