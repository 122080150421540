/** @jsxRuntime classic */ // IE11 support
import 'react-app-polyfill/ie11'; // IE11 support
import 'react-app-polyfill/stable'; // IE11 support
import React from 'react'; // Required to enable Strict Mode and also for IE11 support (jsxRuntime classic)
import ReactDOM from 'react-dom';
import App from './App';

import './assets/themes/default.scss';

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  rootElement
);
