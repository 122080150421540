import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Title from '../layout/Title';
import ButtonRow from '../layout/ButtonRow';
import PRow from '../layout/PRow';
import StandardButton from '../buttons/StandardButton';
import useQuery from '../../hooks/usQuery'
import mfaWorkflowImage from '../../assets/images/mfa-workflow-image.svg'
import mfaWorkflowMobileImage from '../../assets/images/mfa-workflow-mobile-image.svg'

const NewUserPage = () => {
    let query = useQuery();
    let queryString = query.get("state") ? `/continue?state=${query.get("state")}` : '/continue';
    // eslint-disable-next-line no-undef
    let finalUrl = `${process.env.REACT_APP_SET_UP_URL}${queryString}`;
    // eslint-disable-next-line no-undef
    let helpUrl = `${process.env.REACT_APP_HELP_URL}`;
    return (
        <section className="container">
            <Row>
                <Col sm={{span: 12}} lg={{span: 11, offset: 1}} className="mb-5">
                    <div className="card text-font">
                        <div className="card-body mb-5">
                            <Container>
                                <Title showLock={true}>
                                    <h1>Secure Login Setup</h1>
                                </Title>
                                <PRow colSpan={ 10 }>
                                    <h2 className="mt-3 mb-3">How it works</h2>
                                    <p>
                                        When you login from an untrusted device you will either be asked to approve the login request from your mobile, or you will be asked to enter a 6-digit verification code to verify your identity. <a href={helpUrl} target="_blank" rel="noreferrer">Learn more</a>
                                    </p>
                                    <div className="d-none d-sm-none d-md-block d-lg-block, d-xl-block"><img src={mfaWorkflowImage} className="mfa-workflow-image"/></div>
                                    <div className="d-block d-sm-block d-md-none d-lg-none, d-xl-none"><img src={mfaWorkflowMobileImage} className="mfa-workflow-mobile-image"/></div>
                                </PRow>
                                <ButtonRow>
                                    <StandardButton type="primary" href={finalUrl}>
                                        <strong>Continue</strong>
                                    </StandardButton>
                                </ButtonRow>
                            </Container>
                        </div>
                    </div>
                </Col>
            </Row>
        </section>
    )
};

export default NewUserPage;
