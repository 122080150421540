import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap'

const ButtonRow = (props) => (
    <Row>
        <Col md={{span:10, offset: 1}} className="mt-4 mb-2">
            <div className="d-flex flex-row flex-wrap justify-content-center">
                {props.children}
            </div>
        </Col>
    </Row>
);


ButtonRow.propTypes = {
    children: PropTypes.node.isRequired,
}

export default ButtonRow;





