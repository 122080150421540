import React from 'react';
import HelpIconSvg from '../../assets/images/help-icon.svg'
const HelpIcon = () => {
    // eslint-disable-next-line no-undef
    let helpUrl = `${process.env.REACT_APP_HELP_URL}`;
    return (
        <a href={helpUrl} target="_blank" rel="noreferrer" className="help-icon" >
            <img src={HelpIconSvg} alt="help icon" width="68" height="39"/>
        </a>
    )
};

export default HelpIcon;
