import React from 'react';
import PropTypes from 'prop-types';

const StandardButton = (props) => (
    <div className="p-2">
        {props.type == 'primary'
            ?   <a className="btn btn-primary btn-standard btn-padding btn-font" rel="noopener noreferrer" href={props.href}>
                    {props.children}
                </a>
            :   <a className="btn btn-secondary btn-standard btn-padding btn-font" rel="noopener noreferrer" href={props.href}>
                    {props.children}
                </a>
        }
    </div>
);

StandardButton.propTypes = {
    children: PropTypes.element.isRequired,
    href: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default StandardButton;
