import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import StandardButton from '../buttons/StandardButton';
import Title from '../layout/Title';
import PRow from '../layout/PRow';
import ButtonRow from '../layout/ButtonRow';
import useQuery from '../../hooks/usQuery'

const NewUserPage = () => {
    let query = useQuery();
    let queryString = query.get("state") ? `/continue?state=${query.get("state")}` : '/continue';
    // eslint-disable-next-line no-undef
    let finalUrl = `${process.env.REACT_APP_SET_UP_URL}${queryString}`;
    return(
        <section className="container">
            <Row>
                <Col sm={{span: 12}} lg={{span: 11, offset: 1}} className="mb-5">
                    <div className="card text-font">
                        <div className="card-body">
                            <Container>
                                <Title showLock={true}>
                                    <h1>It’s time to update your log in credentials</h1>
                                </Title>
                                <PRow>
                                    <p>
                                        Click the button below to log in to the TradeStation Client Center to update your username and password.
                                    </p>
                                </PRow>
                                <PRow>
                                    <h2 className="mt-3 mb-3">Why do I need to do this?</h2>
                                    <p>
                                        We strive to keep your account safe. Updating your username and password helps ensure your account is secure.
                                    </p>
                                </PRow>
                                <ButtonRow>
                                    <StandardButton type="primary" href={finalUrl}>
                                        <strong>Update Now</strong>
                                    </StandardButton>
                                </ButtonRow>
                            </Container>
                        </div>
                    </div>
                </Col>
            </Row>
        </section>
    )
};

export default NewUserPage;
