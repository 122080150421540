// A custom hook that builds on useLocation to parse
// the query string for you.
//import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// eslint-disable-next-line no-unused-vars
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default useQuery;
